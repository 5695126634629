<!-- Created by henian.xu on 2018/7/20. -->

<template>
    <page>
        <!-- 表单区 -->
        <template slot="secondHeader">
            <el-tabs
                class="classify-tabs"
                v-model="queryFormModel.type"
                type="card"
                @tab-click="onQuery()"
            >
                <el-tab-pane
                    label="天视图"
                    name="1"
                />
                <el-tab-pane
                    label="月视图"
                    name="2"
                />
            </el-tabs>
            <el-form
                ref="queryForm"
                class="second-header npa-b"
                size="small"
                :inline="true"
                :model="queryFormModel"
            >
                <el-form-item
                    prop="statDateDay"
                    label="统计时间"
                    v-show="queryFormModel.type == 1"
                    key="statDateDay"
                >
                    <el-date-picker
                        :picker-options="$utils.CommonConfig.pickerOptions"
                        v-model="queryFormModel.statDateDay"
                        type="daterange"
                        format="yyyy-MM-dd"
                        value-format="yyyy-MM-dd"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                    />
                </el-form-item>
                <el-form-item
                    label="统计时间"
                    v-show="queryFormModel.type == 2"
                    key="statDateMonth"
                >
                    <el-date-picker
                        v-model="queryFormModel.statBeginDateMonth"
                        type="month"
                        format="yyyy-MM"
                        value-format="yyyy-MM"
                        placeholder="选择月"
                    />
                    至
                    <el-date-picker
                        v-model="queryFormModel.statEndDateMonth"
                        type="month"
                        format="yyyy-MM"
                        value-format="yyyy-MM"
                        placeholder="选择月"
                    />
                </el-form-item>
                <el-form-item
                    prop="statBeginDateMonth"
                />
                <el-form-item
                    prop="statEndDateMonth"
                />
                <el-form-item
                    prop="storeName"
                    label="门店名称"
                >
                    <el-input
                        v-model="queryFormModel.storeName"
                        placeholder="请输入门店名称"
                    />
                </el-form-item>
                <el-form-item
                    prop="orderByKey"
                    label="排序类型"
                >
                    <el-select
                        v-model="queryFormModel.orderByKey"
                        placeholder="请选择"
                    >
                        <el-option
                            key="1"
                            label="销量"
                            value="1"
                        />
                        <el-option
                            key="2"
                            label="销售额"
                            value="2"
                        />
                    </el-select>
                </el-form-item>
                <el-form-item
                    prop="orderByType"
                    label="排序方式"
                >
                    <el-select
                        v-model="queryFormModel.orderByType"
                        placeholder="请选择"
                    >
                        <el-option
                            key="1"
                            label="从高到低"
                            value="1"
                        />
                        <el-option
                            key="2"
                            label="从低到高"
                            value="2"
                        />
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button
                        type="primary"
                        @click="onQuery()"
                    >
                        查询
                    </el-button>
                    <el-button
                        type="success"
                        @click="onReset"
                    >
                        重置
                    </el-button>
                </el-form-item>
            </el-form>
        </template>

        <!-- 内容 -->
        <div
            class="body"
            slot="body"
            v-loading="querying"
        >
            <!-- 表格 -->
            <el-card
                size="small"
                shadow="never"
            >
                <div class="item">
                    <el-row
                        justify="space-between"
                    >
                        <el-col
                            :span="12"
                        >
                            <ECharts
                                class="echarts-demo"
                                :options="echartsOptions2"
                            />
                        </el-col>
                        <el-col
                            :span="12"
                        >
                            <ECharts
                                class="echarts-demo"
                                :options="echartsOptions3"
                            />
                        </el-col>
                    </el-row>
                </div>
            </el-card>
            <el-table
                stripe
                border
                size="small"
                :data="[totalData,...tableData]"
                style="width: 100%"
                class="ma-t"
            >
                <el-table-column
                    type="index"
                    width="50"
                />
                <el-table-column
                    prop="storeName"
                    label="门店名称"
                    min-width="100"
                />
                <el-table-column
                    prop="orderNum"
                    label="销量"
                    min-width="50"
                />
                <el-table-column
                    prop="orderAmount"
                    label="销售额"
                    min-width="50"
                />
                <el-table-column
                    fixed="right"
                    label="操作"
                    width="90"
                >
                    <template slot-scope="scope">
                        <el-button
                            type="text"
                            size="small"
                            v-if="scope.row.storeId"
                            @click="goodsSaleStatDetail(scope.row.storeId)"
                        >
                            查看详情
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>

        <!-- 弹框 -->
        <el-dialog
            title="查看报表"
            center
            width="1200px"
            :visible.sync="eChartDialog.isVisible"
            @closed="onEChartDialogCancel"
        >
            <ECharts
                class="echarts-demo"
                :options="echartsOptions4"
            />
            <div
                slot="footer"
                class="dialog-footer"
            >
                <el-button
                    size="small"
                    @click="onEChartDialogCancel"
                >
                    关闭
                </el-button>
            </div>
        </el-dialog>
    </page>
</template>

<script>
import pagesMixin from '@/mixin/layout/pageMixin';
import { queryFormList } from 'vmf/src/mixins';

export default {
    name: 'GoodsSaleStat',
    mixins: [pagesMixin, queryFormList],
    data() {
        return {
            queryFormModel: {
                type: '1',
                orderByKey: '1',
                orderByType: '1',
                storeName: '',
                statDateDay: '',
                statBeginDateMonth: '',
                statEndDateMonth: '',
            },
            // 新增/编辑
            eChartDialog: {
                // 对话框显示
                isVisible: false,
                chartsData: {},
                result: [],
            },
            // 表格数据
            tableData: [],
            totalData: {},
        };
    },
    methods: {
        /* -- 方法 -- */
        // 请求列表数据
        getListData(queryData) {
            return this.$api.Stat.MarketingStat.storeMarketingStatList(queryData).then(json => {
                const res = json.data;
                let orderNum = 0;
                let orderAmount = 0;
                res.data.forEach(item => {
                    orderNum += item.orderNum;
                    orderAmount += item.orderAmount;
                });
                this.totalData = {
                    storeName: '合计',
                    orderNum,
                    orderAmount,
                };
                this.tableData = res.data;
                return Promise.resolve(json);
            });
        },
        /* -- 事件 -- */
        goodsSaleStatDetail(storeId) {
            this.$api.Stat.MarketingStat.storeMarketingStatDetail({
                ...this.queryFormModel,
                storeId,
            }).then(json => {
                const res = json.data.data;
                this.eChartDialog.chartsData = res.chartsData;
                this.eChartDialog.result = res.result;
            });
            this.eChartDialog.isVisible = true;
        },
        onEChartDialogCancel() {
            this.eChartDialog.isVisible = false;
        },
        exportExcel() {
            let statBeginDate = null;
            let statEndDate = null;
            if (this.queryFormModel.statDateDay != null && this.queryFormModel.statDateDay.length === 2) {
                [statBeginDate, statEndDate] = this.queryFormModel.statDateDay;
            }
            this.$http.href('/stat/goodsStat/exportExcel', {
                ...this.queryFormModel,
                statBeginDate,
                statEndDate,
            });
        },
    },
    computed: {
        echartsOptions2() {
            const dataList = [];
            const title = [];
            const list = JSON.parse(JSON.stringify(this.tableData)).sort((a, b) => {
                return b.orderNum - a.orderNum;
            });
            list.forEach(item => {
                if (list.indexOf(item) <= 9) {
                    title.push(item.storeName);
                    dataList.push({ value: item.orderNum, name: item.storeName });
                }
            });
            return {
                title: {
                    text: '订单数量占比',
                    left: 'center',
                },
                tooltip: {
                    trigger: 'item',
                    formatter: '{a} <br/>{b} : {c} ({d}%)',
                },
                legend: {
                    orient: 'vertical',
                    left: 'left',
                    data: title,
                },
                series: [
                    {
                        name: '数量占比',
                        type: 'pie',
                        radius: '55%',
                        center: ['50%', '60%'],
                        data: dataList,
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: 'rgba(0, 0, 0, 0.5)',
                            },
                        },
                        label: {
                            formatter: '{b} ({d}%)',
                        },
                    },
                ],
            };
        },
        echartsOptions3() {
            const dataList = [];
            const title = [];
            const list = JSON.parse(JSON.stringify(this.tableData)).sort((a, b) => {
                return b.orderAmount - a.orderAmount;
            });
            list.forEach(item => {
                if (list.indexOf(item) <= 9) {
                    title.push(item.storeName);
                    dataList.push({ value: item.orderAmount, name: item.storeName });
                }
            });
            return {
                title: {
                    text: '订单金额占比',
                    left: 'center',
                },
                tooltip: {
                    trigger: 'item',
                    formatter: '{a} <br/>{b} : {c} ({d}%)',
                },
                legend: {
                    orient: 'vertical',
                    left: 'left',
                    data: title,
                },
                series: [
                    {
                        name: '金额占比',
                        type: 'pie',
                        radius: '55%',
                        center: ['50%', '60%'],
                        data: dataList,
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: 'rgba(0, 0, 0, 0.5)',
                            },
                        },
                        label: {
                            formatter: '{b} ({d}%)',
                        },
                    },
                ],
            };
        },
        echartsOptions4() {
            const orderNumData = [];
            const payableAmountData = [];
            const dayData = [];
            this.eChartDialog.result.forEach(item => {
                orderNumData.push(item.orderNum);
                payableAmountData.push(item.orderAmount);
                dayData.push(item.orderTime);
            });
            return {
                title: {
                    left: 'left',
                    text: this.eChartDialog.chartsData.title,
                },
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross',
                        crossStyle: {
                            color: '#999',
                        },
                    },
                },
                grid: {
                    top: '20%',
                    left: '3%',
                    right: '3%',
                    bottom: '3%',
                    containLabel: true,
                },
                toolbox: {
                    feature: {
                        // dataView: { show: true, readOnly: false },
                        magicType: { show: true, type: ['line', 'bar'] },
                        restore: { show: true },
                        saveAsImage: { show: true },
                    },
                },
                legend: {
                    data: ['订单数量', '销售金额'],
                },
                xAxis: [
                    {
                        type: 'category',
                        data: dayData,
                        axisPointer: {
                            type: 'shadow',
                        },
                    },
                ],
                yAxis: [
                    {
                        type: 'value',
                        name: '数量',
                        // min: 0,
                        // max: 250,
                        // interval: 50,
                        axisLabel: {
                            formatter: '{value}',
                        },
                        splitLine: {
                            show: false,
                        },
                    },
                    {
                        type: 'value',
                        name: '金额',
                        // min: 0,
                        // max: 25,
                        // interval: 5,
                        axisLabel: {
                            formatter: '{value}',
                        },
                        splitLine: {
                            show: false,
                        },
                    },
                ],
                series: [
                    {
                        name: '订单数量',
                        type: 'bar',
                        data: orderNumData,
                    },
                    {
                        name: '销售金额',
                        type: 'line',
                        yAxisIndex: 1,
                        data: payableAmountData,
                    },
                ],
            };
        },
    },
    created() {
        this.queryFormModel.statDateDay = [this.$utils.getSysDayDate(), this.$utils.getSysDayDate()];
        this.queryFormModel.statBeginDateMonth = this.$utils.getSysYearFirstMonthDate();
        this.queryFormModel.statEndDateMonth = this.$utils.getSysMonthDate();
    },
};
</script>

<style lang="scss">
.echarts-demo {
    width: 100%;
}
</style>
