<!-- Created by henian.xu on 2018/7/20. -->

<template>
    <page>
        <!-- 表单区 -->
        <template slot="secondHeader">
            <el-form
                ref="queryForm"
                class="second-header npa-b"
                size="small"
                :inline="true"
                :model="queryFormModel"
            >
                <el-form-item
                    prop="statDateDay"
                    label="统计时间"
                >
                    <el-date-picker
                        :picker-options="$utils.CommonConfig.pickerOptions"
                        v-model="queryFormModel.statDateDay"
                        type="daterange"
                        format="yyyy-MM-dd"
                        value-format="yyyy-MM-dd"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                    />
                </el-form-item>
                <el-form-item
                    prop="behaviorType"
                    label="行为类型"
                >
                    <dictionaries-picker
                        type="behaviorType"
                        v-model="queryFormModel.behaviorType"
                    />
                </el-form-item>
                <el-form-item>
                    <el-button
                        type="primary"
                        @click="onQuery()"
                    >
                        查询
                    </el-button>
                    <el-button
                        type="success"
                        @click="onReset"
                    >
                        重置
                    </el-button>
                </el-form-item>
            </el-form>
        </template>

        <!-- 内容 -->
        <div
            class="body"
            slot="body"
            v-loading="querying"
        >
            <!-- 表格 -->
            <el-table
                stripe
                border
                size="small"
                :data="tableData"
                style="width: 100%"
            >
                <el-table-column
                    prop="behaviorTypeName"
                    label="行为类型"
                    min-width="100"
                />
                <el-table-column
                    prop="peopleNum"
                    label="人数"
                    min-width="100"
                />
                <el-table-column
                    prop="frequency"
                    label="次数"
                    min-width="100"
                />
                <el-table-column
                    prop="peoplePer"
                    label="人均次数"
                    min-width="100"
                />
                <el-table-column
                    prop="duration"
                    label="时长（秒）"
                    min-width="100"
                />
                <el-table-column
                    prop="durationPer"
                    label="人均时长（秒）"
                    min-width="100"
                />
                <el-table-column
                    fixed="right"
                    label="操作"
                    width="90"
                >
                    <template slot-scope="scope">
                        <el-button
                            type="text"
                            size="small"
                            @click="$router.push('/stat/buyerBehavior/buyerBehaviorHistory/detail/' + scope.row.behaviorType+ '?statDateDay=' + queryFormModel.statDateDay[0] + '&statDateDay=' + queryFormModel.statDateDay[1])"
                        >
                            查看详情
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>

            <!-- 分页 -->
            <div class="ma-t ta-r">
                <pagination
                    v-model="pagination"
                    @input="onQuery"
                />
            </div>
        </div>
    </page>
</template>

<script>
import pagesMixin from '@/mixin/layout/pageMixin';
import { queryFormList } from 'vmf/src/mixins';

export default {
    name: 'BuyerBehavior',
    mixins: [pagesMixin, queryFormList],
    data() {
        return {
            queryFormModel: {
                statDateDay: '',
                behaviorType: '',
            },
            // 表格数据
            tableData: [],
        };
    },
    methods: {
        /* -- 方法 -- */
        // 请求列表数据
        getListData(queryData) {
            return this.$api.Stat.BuyerBehaviorLogStat.findBuyerBehaviorLogStatHistoryList(queryData).then(json => {
                const res = json.data;
                this.tableData = res.data;
                this.pagination = res.pagination;
                return Promise.resolve(json);
            });
        },
    },
    created() {
        // this.queryFormModel.statDateDay = [this.$utils.getSysDayDate(), this.$utils.getSysDayDate()];
    },
};
</script>

<style lang="scss" scoped>
</style>
